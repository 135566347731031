import { React, useState, useEffect } from 'react';
import './ReportsPage.css';

export default function ReportsPage() {

    const [reportType, setReportType] = useState(0)
    const [yearlyBookings, setYearlyBookings] = useState({ "total":0, "monthly":[] })
    const [todaysBookings, setTodaysBookings] = useState([])
    const [loading, setLoading] = useState(false)

    async function GetYearlyBookings() {
        await fetch('https://mrwp.kardall.com/yearlyreport', {
                method: 'GET',
            })
            .then( r => r.json())
            .then( d => setYearlyBookings(d))
            .catch(err => console.log(err))
        setLoading(false)
    }

    async function GetTodaysBookings() {
        var today = new Date(Date.now())
        var month = today.getMonth()+1
        if(month > 12) { month = 1 }
        var day = today.getDate()
        //console.log(`https://mrwp.kardall.com/details/${today.getFullYear()}/${month}/${day}`)
        await fetch(`https://mrwp.kardall.com/details/${today.getFullYear()}/${month}/${day}`, { method: 'GET'})
        .then( r=> r.json())
        .then(todays_bookings => {
            setTodaysBookings(todays_bookings.data)
        })
        .catch(err => console.log(err))
    }
    function getYearlyTotals() {
        return (
            <>
            <div>Total Booked Students: {yearlyBookings.total.toString()}</div>
            <div className="monthly-header">
                {yearlyBookings.monthly.map( m => {
                    return (<div key={m.date}>
                        <div>{m.date}</div>
                        <div>{m.count}</div>
                    </div>)
                })}
            </div>
            </>
        )
    }

    function getTodaysSchools() {
        return (
            <>
            <div className="monthly-header">
                {
                    todaysBookings.map( booking => {
                        if(booking.school_name !== null) {
                            return (
                                <div className='column booking_container'>
                                <div key={booking.booking_id}>
                                    <div><strong>{booking.school_name}</strong></div>
                                    <div>Head Count: <strong>{booking.booking_group}</strong></div>
                                    <div>Grades: {booking.booking_lograde} to {booking.booking_higrade}</div>
                                    <div>Arrival: {booking.booking_arrival}</div>
                                    <div>Departure: {booking.booking_departure}</div>
                                    <div>Ski: <strong>{booking.booking_ski}</strong></div>
                                    <div>Snowboard: <strong>{booking.booking_snowboard}</strong></div>
                                    <div>Add Tube: <strong>{booking.booking_addtube}</strong></div>
                                    { booking.booking_disabled > 0 ? (<><div>Disabled: {booking.booking_disabled}</div><div>Disabled Notes: {booking.booking_disabled_notes}</div></>) : ""}
                                    <div>Lunch Time: {booking.booking_pizza_time} (lunches: {booking.booking_pizza})</div>
                                    
                                </div>
                                </div>
                            )
                        }
                        
                    })
                }
            </div>
            </>
        )
    }
    useState(() => {
        if(!loading) {
            setLoading(true)
            GetTodaysBookings()
            GetYearlyBookings()
        }
    }, [yearlyBookings])

    return (
        <div className="admin-container">
                <div className="column">
                    <h1>Seasons Student Booked</h1>
                    <div>
                        {
                            getYearlyTotals()
                        }
                    </div>
                </div>
                <div className="column pagebreak">
                    <h1>Today's Schools</h1>
                    <div className="md-text">
                        {
                            getTodaysSchools()
                        }
                    </div>
                </div>
        </div>
    )
}